import posthog from "posthog-js";
import React from "react";
import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = "hhm6xlhq";
const POSTHOG_KEY = "phc_xD1DXKhfQSwPYqgim8E9i5Or3ITD1VXqEM6H5z4Vsn2";

export const wrapRootElement = ({ element }) => {
  if (typeof window !== "undefined") {
    posthog.init(POSTHOG_KEY, {
      api_host: "https://eu.i.posthog.com",
    });

    if (process.env.NODE_ENV !== "production") {
      posthog.opt_out_capturing();
    }

    if (window.ttq) {
      window.ttq.page();
    }

    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }

  return (
    <IntercomProvider initializeDelay={5000} appId={INTERCOM_APP_ID}>
      {element}
    </IntercomProvider>
  );
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    if (location.pathname === "/") {
      return;
    }
    posthog.capture("$pageview", {
      distinct_id: posthog.get_distinct_id(),
      $current_url: location.href,
      $pathname: location.pathname,
      $search_engine: document.referrer,
    });

    if (window.ttq) {
      window.ttq.page();
    }

    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }
};
